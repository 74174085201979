<template>
    <b-row>
        <b-col
            cols="12"
            class="box-section payment-methods"
        >
            <p class="heading sbold">
                {{ $t('page.checkout.paymentOptionsHeading') }}
            </p>
            <div class="boxes">
                <div
                    v-for="(payment, code) in paymentMethods"
                    :key="`payment--${code}`"
                    class="box"
                    :class="{
                        selected: selectedMethod == code,
                        disabled: !payment.enabled,
                    }"
                >
                    <div
                        class="box-container"
                        @click="select(code)"
                    >
                        <div class="box-header">
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        type="radio"
                                        name="payment-method"
                                        :checked="code == selectedMethod"
                                        :value="code"
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">{{ getLabel(code) }}</div>
                            </label>
                        </div>
                        <div class="box-content">
                            <caption
                                :class="'payment ' + code"
                            >
                                <span>{{ getText(code, payment) }}</span>
                            </caption>
                            <card-types v-if="code == 'online'" />
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapActions, mapState } from 'vuex';

    import CardTypes from '@/components/checkout/CardTypes';
    export default {
        name: 'CheckoutPaymentMethods',
        components: { CheckIcon, CardTypes },
        data() {
            return {
                selectedMethod: 'online',
                firstLoad: true,
            };
        },
        computed: {
            ...mapState('checkout', ['paymentMethods', 'selectedData']),
        },
        methods: {
            ...mapActions('checkout', ['getCheckoutData']),
            select(code) {
                if (this.selectedMethod != code) {
                    this.selectedMethod = code;
                    localStorage.setItem('paymentType', this.selectedMethod);
                    this.getCheckoutData();
                }
            },
            getLabel(code) {
                return this.$t('page.checkout.' + code.toLowerCase() + 'PaymentLabel');
            },
            getText(code, payment) {
                if (!payment.enabled) {
                    return payment.limit ? this.$t('page.checkout.rambursLimitText', {
                        value: payment.limit,
                    }) : this.$t('page.checkout.rambursLocalShippingText');
                }
                return this.$t('page.checkout.' + code.toLowerCase() + 'PaymentText');
            },
            initialize() {
                let selectedMethod = this.selectedData.payment_type;
                this.selectedMethod = selectedMethod;
                localStorage.setItem('paymentType', selectedMethod);
                this.firstLoad = false;
            },
        },
        mounted() {
            this.initialize();
        },
        watch:{
            'selectedData.payment_type':{
                handler(value) {
                    this.selectedMethod = value;
                    localStorage.setItem('paymentType', value);
                },
                deep: true,
            }
        }
    };
</script>
