<template>
    <div class="container checkout">
        <loading-spinner
            v-if="loading"
            class="fixed"
        />
        <b-row :class="{ transparent: loading }">
            <b-col
                cols="12"
                class="page-header"
            >
                <h4>{{ $t('page.checkout.detailsHeading') }}</h4>
                <checkout-status-progress-bar />
            </b-col>
        </b-row>
        <b-row>
            <b-col
                v-if="existData"
                :class="{ transparent: loading }"
                cols="12"
                md="8"
            >
                <user-delivery-data
                    v-if="!deliveredViaEmail"
                    id="delivery"
                    :class="{ 'has-error': deliveryError }"
                />
                <user-billing-data
                    id="billing"
                    page="checkout"
                    :delivered-via-email="deliveredViaEmail"
                    :class="{ 'has-error': billingError }"
                />
                <checkout-delivery-methods
                    :class="{ 'has-error': deliveryMethodError }"
                />
                <checkout-payment-methods
                    id="payment"
                    :class="{ 'has-error': paymentMethodError }"
                />
            </b-col>
            <b-col
                v-if="existData"
                :class="{ transparent: loading }"
                cols="12"
                md="4"
            >
                <cart-summary
                    :coupon-code="summaryData.applied_coupon"
                    action-name="Summar"
                    class="summary"
                    :class="{ sticky: sticky }"
                    @addError="addError"
                />
            </b-col>
        </b-row>
        <user-delivery-modal v-if="showDeliveryModal" />
        <user-billing-modal v-if="showBillingModal" />
        <user-company-modal v-if="showCompanyModal" />
        <delete-data-modal
            v-if="showDeleteDataModal"
            @deleteItem="deleteItem"
        />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import CartSummary from './cart/CartSummary';
    import CheckoutDeliveryMethods from './checkout/CheckoutDeliveryMethods';
    import CheckoutPaymentMethods from './checkout/CheckoutPaymentMethods';
    import CheckoutStatusProgressBar from './checkout/CheckoutStatusProgressBar';
    import UserBillingData from './user/UserBillingData';
    import UserDeliveryData from './user/UserDeliveryData';

    const UserDeliveryModal = () => import('@/components/modals/UserDeliveryModal');
    const UserBillingModal = () => import('@/components/modals/UserBillingModal');
    const UserCompanyModal = () => import('@/components/modals/UserCompanyModal');
    const DeleteDataModal = () => import('@/components/modals/DeleteDataModal');
    export default {
        components: {
            CartSummary,
            CheckoutDeliveryMethods,
            UserDeliveryData,
            UserBillingData,
            CheckoutPaymentMethods,
            CheckoutStatusProgressBar,
            UserDeliveryModal,
            UserBillingModal,
            UserCompanyModal,
            DeleteDataModal,
            LoadingSpinner,
        },
        name: 'Checkout',
        data() {
            return {
                sticky: false,
                hash: this.$route.hash,
                screenWidth: 0,
                showDeliveryModal: false,
                showBillingModal: false,
                showCompanyModal: false,
                showDeleteDataModal: false,
                deliveryError: false,
                billingError: false,
                deliveryMethodError: false,
                paymentMethodError: false,
                deliveredViaEmail : false,
            };
        },
        computed: {
            ...mapState('user', [
                'deliveryData',
                'billingData',
                'companyData',
                'modal',
            ]),
            ...mapState('checkout', ['loading', 'summaryData', 'selectedData']),
            lang() {
                return i18n.locale;
            },
            existData() {
                return Object.keys(this.summaryData).length > 0;
            },
        },
        methods: {
            ...mapActions('checkout', ['getCheckoutData']),
            ...mapActions('user', ['getUserData', 'deleteData']),
            async loadData() {
                await this.getCheckoutData();
                await this.getUserData();
                if (this.hash) {
                    this.scrollToAnchorPoint();
                }
                if(
                    this.summaryData.items.products.length == 0 &&
                    this.summaryData.items.vouchers.length > 0 &&
                    this.summaryData.shipping_method.code == 'email'
                ){
                    this.deliveredViaEmail = true;
                    localStorage.setItem('sameData', this.selectedData['same_data']);
                }

            },
            deleteItem() {
                this.deleteData({
                    id: this.modal.data.inputData.id,
                    type: this.modal.data.toDeleteType,
                });
            },
            handleScroll() {
                if (window.scrollY > 100 && !this.sticky) {
                    this.sticky = true;
                } else if (window.scrollY <= 100 && this.sticky) {
                    this.sticky = false;
                }
            },
            scrollToAnchorPoint() {
                this.$nextTick(() => {
                    const refName = this.hash.replace('#', '');
                    const el = document.getElementById(refName);
                    if(el){
                        window.scrollTo(0, el.offsetTop - 100);
                    }
                });
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },

            reInitializeScrollListener() {
                window.removeEventListener('scroll', this.handleScroll);
                this.sticky = false;
                if (this.screenWidth >= 768) {
                    window.addEventListener('scroll', this.handleScroll);
                    if (window.scrollY > 85) {
                        this.sticky = true;
                    }
                }
            },
            addError(type) {
                switch (type) {
                case 'delivery':
                    this.deliveryError = true;
                    break;
                case 'deliveryMethod':
                    this.deliveryMethodError = true;
                    break;
                case 'billing':
                    this.billingError = true;
                    break;
                case 'paymentMethod':
                    this.paymentMethodError = true;
                    break;
                default:
                    this.initializeErrors();
                    break;
                }
            },
            initializeErrors() {
                this.deliveryError = false;
                this.deliveryMethodError = false;
                this.billingError = false;
                this.paymentMethodError = false;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
            this.screenWidth = window.innerWidth;
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
        created() {
            this.loadData();
            if (this.$screen.width >= 768) {
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        destroyed() {
            if (this.$screen.width >= 768) {
                window.removeEventListener('scroll', this.handleScroll);
            }
        },
        watch: {
            'summaryData.applied_coupon': {
                handler(couponCode) {
                    if (couponCode && !couponCode.valid) {
                        this.$toast.open({
                            message: this.$t('notification.promoCodeRemoved', {
                                name: couponCode.name,
                            }),
                            duration: 10000,
                            position: 'bottom-right',
                            type: 'info',
                        });
                    }
                },
                deep: true,
            },
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
            modal: {
                handler(modal) {
                    switch (modal.type) {
                    case 'delivery':
                        this.showDeliveryModal = modal.show;
                        break;
                    case 'billing':
                        this.showBillingModal = modal.show;
                        break;
                    case 'company':
                        this.showCompanyModal = modal.show;
                        break;
                    case 'delete':
                        this.showDeleteDataModal = modal.show;
                        break;
                    default:
                        break;
                    }
                },
                deep: true,
            },
            screenWidth: {
                handler() {
                    this.reInitializeScrollListener();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.checkout.title'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/box.scss';
</style>
<style scoped lang="scss">
.checkout {
  .summary {
    @include media-breakpoint-up(lg) {
      margin-top: 24px;
    }
    @include media-breakpoint-between(md, lg) {
      margin-top: 12px;
    }
    &.sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 90px;
    }
  }
}
</style>
