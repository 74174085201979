<template>
    <b-row>
        <b-col
            cols="12"
            class="box-section delivery-methods"
        >
            <p class="heading sbold">
                {{ $t('page.checkout.shippingOptionsHeading') }}
            </p>
            <div class="boxes">
                <div
                    v-for="shipping in shippingMethods"
                    :key="`shipping--${shipping.code}`"
                    class="box"
                    :class="{ selected: checkSelected(shipping) }"
                >
                    <div
                        class="box-container"
                        @click="select(shipping)"
                    >
                        <div class="box-header">
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        type="radio"
                                        name="shipping-method"
                                        :checked="checkSelected(shipping)"
                                        :value="shipping.code"
                                        @click.stop
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">{{ shipping.name }}</div>
                            </label>
                        </div>
                        <div class="box-content">
                            <p
                                v-dompurify-html="formatPrice(shipping.price)"
                                class="has-sup sm-sbold"
                            >
                                Ron
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'CheckoutDeliveryMethods',
        components: { CheckIcon },
        data() {
            return {
                firstLoad: true,
            };
        },
        computed: {
            ...mapState('checkout', ['shippingMethods', 'selectedData']),
            ...mapState('user', ['deliveryData']),
        },
        methods: {
            ...mapMutations('checkout', ['setSelectedShipping']),
            ...mapActions('checkout', ['getCheckoutData']),
            select(shipping) {
                if (
                    this.selectedData &&
                    this.selectedData.delivery_method != shipping.code
                ) {
                    let obj = { code: shipping.code, cost: shipping.price };
                    localStorage.setItem('deliveryMethod', JSON.stringify(obj));
                    this.getCheckoutData();
                }
            },
            checkLocalDeliveryAvailability() {
                let deliveryMethodString = localStorage.getItem('deliveryMethod');
                let deliveryMethod;

                try {
                    deliveryMethod = JSON.parse(deliveryMethodString);
                } catch (error) {
                    deliveryMethod = { code: 'error' };
                }

                let selected = this.deliveryData.find(
                    (contact) => contact.selected
                );
                if (
                    deliveryMethod.code == 'predare-personala' &&
                    selected.contact_city !== 'Târgu Mureș'
                ) {
                    let firstKey = Object.keys(this.shippingMethods)[0];
                    let firstShippingMethod = this.shippingMethods[firstKey];

                    let obj = {
                        code: firstShippingMethod.code,
                        cost: firstShippingMethod.price,
                    };
                    localStorage.setItem('deliveryMethod', JSON.stringify(obj));
                }
            },
            formatPrice(price) {
                return splitedPrice(price);
            },
            checkSelected(data) {
                if (data.code == this.selectedData.delivery_method) {
                    return true;
                }
                return false;
            },
        },
        watch: {
            deliveryData: {
                handler() {
                    if (!this.firstLoad) {
                        this.getCheckoutData();
                    }
                    this.checkLocalDeliveryAvailability();
                    this.firstLoad = false;
                },
                deep: true,
            },
        },
    };
</script>
